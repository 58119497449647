
// Reducers

const expensesReducerDefaultState = [];

export default (state = expensesReducerDefaultState, action) => {

    switch (action.type) {
        case 'ADD_EXPENSE':
            return [...state, action.expense]

        case 'EDIT_EXPENSE':
            return state.map((expense) => {
                if (expense.id === action.id) {
                    // console.log('edit expense:', expense);
                    return {
                        ...expense,         // This will get all of the previous properties
                        ...action.updates   // This is overwrite any of the updates we passed in
                    }
                } else {
                    return expense;
                }
            });

        case 'REMOVE_EXPENSE':
            return state.filter(({ id }) => id != action.id);

        default:
            return state;
    }
}