import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { editExpense, removeExpense } from '../actions/expenses';
import ExpenseForm from './ExpenseForm';

const EditExpensePage = () => {
    const { id } = new useParams();
    const expense = useSelector(state => state.expenses.find((expense) => expense.id === id));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <div>
            <ExpenseForm 
                expense={expense}
                onSubmit={(expense) => {
                    dispatch(editExpense(id, expense));
                    navigate('/');
                }}
            />

            <button onClick={(e) => {
                dispatch(removeExpense({ id }));
                navigate('/');
            }}>Remove</button>
        </div>
    );
};

export default EditExpensePage;